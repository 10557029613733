import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  offlineRoot: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1)
    },
    justifyItems: "center",
    display: "grid"
  },
  contentContainer: {
    whiteSpace: "pre-wrap",
    fontSize:"1.5rem",
    color: theme.nprogress.color,
  },
  warning: {
    marginTop: 60,
    fontWeight:"bold",
    color: theme.palette.error.main,
    [theme.breakpoints.up("md")]: {
        fontSize:"4.0rem",
    },
    [theme.breakpoints.down("sm")]: {
        fontSize:"2.5rem",
    },
  },
  warningGrid: {
    justifyItems: "center",
    display: "grid"
  },
  logo: {
    justifyContent: "center"
  },
  mainGrid: {
    background: theme.palette.background.default
  }
}));