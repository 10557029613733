import React, { useState, useEffect, useLayoutEffect, useCallback } from "react";
import { useStyles } from "./styles";
import clsx from "clsx";
import { useParams } from "react-router-dom";
import AuthService from "../../services/AuthService";
import apiClient, { setAuthentication as setApiAuth } from "../../auth/apiClient";
import { setAuthenticationState } from "../../reducers/auth/authReducer";
import OnePassService from "../../services/OnePassService";
import GenericError from "../../components/MobilePayment/GenericError";
import OnePassDetails from "../../components/OnePass/ViewOnePass/OnePassDetails";
import useAuthContext from "../../hooks/useAuthContext";
import { Grid, CircularProgress } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";

const authService = new AuthService(apiClient);
const onePassService = new OnePassService(apiClient);
const emptyGuid = "00000000-0000-0000-0000-000000000000";

const OnePassPage = () => {
  const classes = useStyles();
  const { ticketId } = useParams();
  const [currentTicket, setCurrentTicket] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { authReducer } = useAuthContext();
  const [, authDispatch] = authReducer;
  const theme = useTheme();

  const getAuth = async () => {
    try {
      var appClientToken = await authService.getMobilePayAuthToken();
      if (appClientToken !== "") {
        setApiAuth(appClientToken);
        authDispatch({
          type: setAuthenticationState,
          payload: { properties: [] },
        });
        setIsAuthenticated(true);
      }
    } catch (error) {
      console.error("Error Authenticating", error);
    }
  };

  const getTicketData = useCallback(async () => {
    try {
      var response = await onePassService.getOnePassDetails(ticketId);
      const ticket = response.data;
      if (ticket && ticket.ticketID && ticket.ticketID !== emptyGuid) {
        setCurrentTicket(ticket);
      } else {
        setErrorMessage(["Error retrieving ONE Pass."]);
      }
    } catch (error) {
      setErrorMessage(["Error retrieving ONE Pass."]);
    } finally {
      setIsLoading(false);
    }
  }, [ticketId]);

  useEffect(() => {
    if (!isAuthenticated) {
      getAuth();
    }
  }, [authDispatch, isAuthenticated]);

  useEffect(() => {
    if (ticketId && isAuthenticated) {
      getTicketData();
    }
  }, [ticketId, isAuthenticated]);

  useLayoutEffect(() => {
    document.body.style.backgroundColor = theme.palette.background.level1;
  }, []);

  return (
    <div className={clsx(classes.root)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <img className={classes.logo} alt="company logo" src="/images/logos/a1-logo-stacked-dark.svg" />
        </Grid>
      </Grid>
      <div className={clsx(classes.contentContainer, "onepass-content")}>
          <>
            {isLoading === true ? (
              <CircularProgress size={60} />
            ) : (
              errorMessage
                ? <GenericError message={errorMessage}></GenericError>
                : <>
                  {isAuthenticated && currentTicket && (
                    <div>
                      <OnePassDetails
                        ticketId={currentTicket?.ticketID}
                        validFrom={currentTicket?.validFrom}
                        validTo={currentTicket?.validTo}
                        status={currentTicket?.transactionState}
                      ></OnePassDetails>
                    </div>
                  )}
                </>
            )}
          </>
      </div>
    </div>
  );
};

export default OnePassPage;
