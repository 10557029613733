import React, { useEffect, useReducer, useState } from "react";
import useThemeContext from "../../../hooks/useThemeSwitch";
import { setRootContainerWidth } from "../../../reducers/layout/layoutReducer";
import { Grid } from "@material-ui/core";
import { useStyles } from "./styles";
import ScheduledReportsProvider from "../../../providers/ScheduledReportsProvider";
import ScheduledReportsList from "../../../components/ScheduledReports/ScheduledReportsList";
import useHasPermissions from "../../../hooks/useHasPermissions";
import { useFeatureFlag } from "../../../hooks/useFeatureFlags";
import { Drawer, Box, useMediaQuery, useTheme } from "@material-ui/core";
import ScheduledReportsComposer from "../../../components/ScheduledReports/ScheduledReportsComposer";

const initialScheduledReportsPageState = {
  formOpen: false,
  scheduledSettings: undefined
};
function scheduledReportsPageReducer(state, { type, payload }) {
  switch (type) {
    case "EDIT_SCHEDULED_REPORTS":
      return {
        ...state,
        scheduledSettings: payload.scheduledsettings,
        scheduledrptId: payload.scheduledrptid,
        formOpen: true };
    case "CLOSE_FORM":
      return { ...state, scheduledSettings: undefined, formOpen: false };
    case "ADD_SCHEDULED_REPORTS":
      return {
        ...state,
        scheduledSettings: payload.scheduledsettings,
        scheduledrptId: undefined,
        formOpen: true };
    default:
      return state;
  }
}

const ScheduledReportsPage = props => {
  const classes = useStyles();
  const { layoutReducer } = useThemeContext();
  const [, layoutDispatch] = layoutReducer;
  const { hasPermissions } = useHasPermissions();
  const hasScheduledReportPermission = hasPermissions(["ScheduledReports"]);
  const scheduledReportFeatureFlag = useFeatureFlag("Scheduled Reports");
  const [state, dispatch] = useReducer(
    scheduledReportsPageReducer,
    initialScheduledReportsPageState
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [refreshTrigger, setRefreshTrigger] = useState(false);

  useEffect(() => {
    layoutDispatch({ type: setRootContainerWidth, payload: false });
  }, [layoutDispatch]);

  const handleAdd = (scheduledsettings) => {
    dispatch({
      type: "ADD_SCHEDULED_REPORTS",
      payload: { scheduledsettings }
    });
  };

  const handleSelectScheduledReports = (scheduledsettings, scheduledrptid) => {
    dispatch({
      type: "EDIT_SCHEDULED_REPORTS",
      payload: { scheduledsettings, scheduledrptid }
    });
  };

  const handleCancel = () => {
    dispatch({ type: "CLOSE_FORM" });
  };

  const handleSave = () => {
    dispatch({ type: "CLOSE_FORM" });
    setRefreshTrigger(prev => !prev);
  };
  

  const showScheduledReportsForm = () => {
    return state.formOpen === true;
  };

  return <>
    <div className={classes.root}>
      {hasScheduledReportPermission && scheduledReportFeatureFlag && (
        <ScheduledReportsProvider>
          <Grid container className={classes.root}>
            <Grid className={classes.contentContainer} item xs={12} lg={12}>
              <ScheduledReportsList
                onAddClick={handleAdd}
                onSelect={handleSelectScheduledReports}
                refreshTrigger={refreshTrigger}
              />
            </Grid>
          </Grid>
        </ScheduledReportsProvider>
      )}
      {showScheduledReportsForm() && (
      <Drawer
        anchor="right"
        open={state.formOpen}
        classes={{ paper: classes.drawerPaper, root: classes.drawer}}
      >
        <Box
          my={1}
          mx={1}
          width={isMobile ? "100%" : "35rem"}
          style={isMobile ? { height: "100%" } : undefined}
        >
          <ScheduledReportsComposer
            scheduledrptId={state.scheduledrptId}
            scheduledSettings={state.scheduledSettings}
            handleCancel={handleCancel}
            handleSave={handleSave}
          />
        </Box>
      </Drawer>
      )}
    </div>
</>
};

export default ScheduledReportsPage;

