import React, { useEffect, useState, useCallback } from "react";
import { ListeningProvider } from "../../hooks/useDeviceListener/useDeviceListenerContext";
import apiClient from "../../auth/apiClient";
import TreeService from "../../services/TreeService";
import useThemeContext from "../../hooks/useThemeSwitch";
import { setRootContainerWidth } from "../../reducers/layout/layoutReducer";
import OrgChart from "../../components/OrgChart";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import * as c from "../../constants/index";
import useHasPermissions from "../../hooks/useHasPermissions";
import { GetAllFacilities, fillEntityLevel } from "../../state/slices/entities";
import useCurrentUser from "../../hooks/useCurrentUser";
import {useCoreEntityContext} from "../../hooks/useCoreEntitySlice";
import {selectAllFacilities, fillEntityLevel as coreFillEntityLevel} from "../../state/slices/CoreEntity";
import {selectAllOrgFacilities} from "../../state/slices/OrgTree";
const treeService = new TreeService(apiClient);

const EntityTree = (props) => {
  const useCoreEntitySlice = useCoreEntityContext();

  // app state
  const contextID = useSelector((state) => useCoreEntitySlice ? state?.coreEntities?.ContextID : state?.entities?.ContextID);
  const currentScopes = useSelector(
    (state) => {
      let scope = GetAllFacilities(state?.entities?.EntityList ?? []);

      if(useCoreEntitySlice) {
        const orgScope = selectAllOrgFacilities(state);
        scope = orgScope.length > 0 ? orgScope : selectAllFacilities(state)
      }
      return scope
    },
    (one, two) => _.isEqual(_.sortBy(one), _.sortBy(two))
  );
  const facilityGroupID = useSelector((state) =>  state.entityScope?.facilityGroupId);

  // local state
  const [dataTree, setDataTree] = useState([]);
  const [treeStructure, setTreeStructure] = useState([]);

  // react hooks
  const history = useHistory();
  const dispatch = useDispatch();

  // app hooks
  const user = useCurrentUser();
  const { layoutReducer } = useThemeContext();
  const [, layoutDispatch] = layoutReducer;
  const { hasPermissions } = useHasPermissions();
  
  const loadEntity = useCallback(
    (eid, fgid) => {
      treeService
        .getTreeStructure(eid, 2)
        .then(async (response) => {
          // make sure fgid belongs to eid
          if (fgid) {
            if(useCoreEntitySlice){
              await dispatch(coreFillEntityLevel({ entityID: fgid, userID: user.UserID, currentContextID: eid }))
            }
            if ( response.data.parententityid === fgid && !useCoreEntitySlice) {
              fillEntityLevel({ entityID: fgid, userID: user.UserID, currentContextID: eid }) // im gunna leave this to keep exising function but this should probably be in a dispatch?
            }
          } else {
            await dispatch(
              useCoreEntitySlice ? coreFillEntityLevel({ entityID: eid, userID: user.UserID, currentContextID: eid }) : fillEntityLevel({ entityID: eid, userID: user.UserID, currentContextID: eid })
            );
          }
          setTreeStructure(response.data);
        })
        .catch((err) => console.log(err));
    },
    [dispatch, user]
  );
  function isViewable(entity, userScopes) {
    if (entity.entitytype?.toLowerCase() === "tenant") {
      return (
        entity.parententityid == null ||
        entity.children == null ||
        !_.isUndefined(_.find(userScopes, { parentID: entity.entityid })) ||
        !_.isUndefined(_.find(userScopes, { parententityid: entity.entityid }))
      );
    } else {
      // do they have permissions to view the overview of this facility?
      return hasPermissions(["overview.view"], false, entity.entityid);
    }
  }

  function walkAndPruneEntityTree(loaded, scoped) {
    // in this function, we will check entities of type facility and higher. If the user does not have access to that facility, pop it and its children out of the collection
    let filtered = [];
    loaded.forEach((entity) => {
      if (
        entity.entitytype?.toLowerCase() !== "facility" &&
        entity.entitytype?.toLowerCase() !== "tenant" &&
        entity.entitytype?.toLowerCase() !== "facilitygroup"
      ) {
        filtered.push(entity);
        return;
      }
      if (isViewable(entity, scoped)) {
        if (entity.children) {
          // prune out the children we can't see and reset the children prop on entity
          entity.children = walkAndPruneEntityTree(entity.children, scoped);
        }
        filtered.push(entity);
      }
    });
    return filtered;
  }

  const onDelete = useCallback(
    (entity) => {
      if (entity.typeID == c.ENTITY_TYPE.Facility) {
        history.go(0);
      } else {
        loadEntity(contextID);
        // *shudders*
        history.go(0);
      }
    },
    [contextID, loadEntity]
  );

  useEffect(() => {
    layoutDispatch({ type: setRootContainerWidth, payload: false });
  }, [layoutDispatch]);

  useEffect(() => {
    loadEntity(contextID, facilityGroupID);
  }, [contextID, facilityGroupID, loadEntity]);

  useEffect(() => {
    const copy = _.cloneDeep(treeStructure);
    setDataTree(walkAndPruneEntityTree(copy, currentScopes));
    // TODO: JSON.stringify feels wrong here, but including currentScopes
    // directly results in infinite renders. Tried using createSelector to
    // no avail. One day someone smarter might address this :(
  }, [treeStructure, JSON.stringify(currentScopes)]);

  if (dataTree.length == 0) {
    return <div></div>;
  } else {
    return (
      <ListeningProvider>
        <OrgChart data={dataTree} loadEntity={loadEntity} onDelete={onDelete} />
      </ListeningProvider>
    );
  }
};

export default EntityTree;
