import React from "react";
import {useSelector} from "react-redux";
import {useStyles} from "./styles";
import {Box, Typography, Divider} from "@material-ui/core";
import CubicCertificates from "../../../components/Administration/CubicCertificates/CubicCertificates";

const CubicCertificatesPage = () => {
  const classes = useStyles();
  const isAdmin = useSelector(state => state?.user?.isAdmin);

  if (!isAdmin) return <></>;

  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant="h4">Cubic Certificate Management</Typography>
      <Divider className={classes.titleDivider}/>
      <Box display="flex" justifyContent="center" alignItems="center">       
          <CubicCertificates></CubicCertificates>        
      </Box>
    </div>
  );
};

export default CubicCertificatesPage;
