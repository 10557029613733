import React, { useEffect } from "react";
import useThemeContext from "../../../hooks/useThemeSwitch";
import { setRootContainerWidth } from "../../../reducers/layout/layoutReducer";
import ValetRequestMonitor from "../../../components/ValetRequestMonitor/ValetRequestMonitor";

const RequestMonitorPage = (props) => {
  const { layoutReducer } = useThemeContext();
  const [, layoutDispatch] = layoutReducer;

  useEffect(() => {
    layoutDispatch({ type: setRootContainerWidth, payload: false });
  }, [layoutDispatch]);

  return <ValetRequestMonitor />;
};
export default RequestMonitorPage;
