import React from "react";
import {Grid} from "@material-ui/core";
import {useStyles} from "./styles";
import {useFlags} from "launchdarkly-react-client-sdk";
import {Typography} from "@material-ui/core";
import clsx from "clsx";
import { Redirect } from "react-router-dom";

export const MaintenancePage = () => {
  const classes = useStyles();
  const { systemOffline } = useFlags();
  const message = systemOffline?.message ?? "The system is currently unavailable.   If you have questions, please contact support.";
  
  return (
    <>
      {systemOffline?.enabled ? (
        <>
        <Grid container alignItems="center" alignContent="center" className={classes.mainGrid}> 
            <Grid item xs={12} className={classes.warningGrid}>
                <Typography className={clsx(classes.warning, "warning")}>SYSTEM OFFLINE</Typography>
            </Grid>
            <Grid item xs={12} className={classes.offlineRoot}>
              <Grid className={classes.contentContainer}>
                <span dangerouslySetInnerHTML={{__html: message}}/>
              </Grid>
            </Grid>
            <Grid container item xs={12} className={classes.logo}>
            <img 
                style={{marginTop:150}}
                width="10%"
                src="/images/logos/a1-logo-stacked-dark.svg"
            />
            </Grid>
        </Grid>
        </>
      ) : (
        <Redirect to={`/home`} />
      )}
    </>
  );
};