import { makeStyles, darken } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    margin:10,
  },
  title: {
    color:darken(theme.palette.amano.main,0.2),
  },
  titleDivider:{
    marginBottom:10,
    marginTop:4,
  }
}));
